import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DateTimeDisplay from './DateTimeDisplay.jsx';
import PlanningBox from './PlanningBox.jsx';
import FilterDrawer from './FilterDrawer.jsx';
import DateRangePicker from './DateRangePicker.jsx';
import UserMenu from './UserMenu.jsx';
import { Box, useTheme, Typography } from "@mui/material";
import Logo from "../../asset/image/logo.svg";
import { useGetMetrics } from '../../hooks/api/useCampaign.js';
import PageRefresh from './PageRefresh.jsx';
import SecondaryAnalysisFilters from './SecondaryAnalysisFilters.jsx';

const Header = () => {

  const [menuToggle, setMenuToggle] = useState(false);
  const userString = localStorage.getItem('user');
  const user = JSON.parse(userString);
  const theme = useTheme();
  const location = useLocation();

  // Check if we're on the secondary analysis page
  const isSecondaryAnalysisPage = location.pathname.includes('/secondary-analysis');

  const { data: metricsData, isLoading: isMetricsDataLoading } = useGetMetrics();

  useEffect(() =>{
    if (menuToggle === true) {
      document.body.classList.add('bt-menu-open');
    }
    else {
      document.body.classList.remove('bt-menu-open');
    }
  },[menuToggle])

  if (isMetricsDataLoading) return <></>

  const metrics = metricsData?.data?.data

  const funToggleMenu = () => {
    setMenuToggle(!menuToggle);
  }


  return (
    <>
      <header>
        <Box
          className="header_wrapper"
          sx={{ backgroundColor: theme.palette.background.headerColor }}
        >
           <Box className="mobile-size-header" display="flex" alignItems="center" width="100%">
               {/* <Box
                 sx={{
                   padding: "10px",
                   paddingLeft:"0px"
                 }}
               >
                 <span class="bt-menu-trigger"  style={{ left: menuToggle ? '100px':'0px',transition:'0.3s ease'}} onClick={() => funToggleMenu()}>
                   <span>Menu</span>
                 </span>
               </Box> */}
               <Box
                 sx={{
                   padding: "10px",
                   flexGrow: 1,
                   textAlign: "center",
                 }}
               >
                 <HeaderTitle user={user} />
               </Box>
               <Box
                 sx={{
                   padding: "10px",
                    paddingRight:"0px"
                 }}
               >
                 <UserMenu />
               </Box>
             </Box>
          <div className="header-inner">
            <div className="right_header_part">
             
              <HeaderTitle user={user}  className="header-title-container"/>
              <div className="right_header_part_date">
                {/* <PageRefresh /> */}
                <DateTimeDisplay />
                <PlanningBox metrics={metrics} />
              </div>
            </div>
            <div className="header_right_bar">
              <div className="DownloadReportAndFilter">
                <PageRefresh />
                {isSecondaryAnalysisPage ? (
                  <SecondaryAnalysisFilters />
                ) : (
                  <FilterDrawer />
                )}
                <DateRangePicker />
              </div>
              <UserMenu className="user-menu-container" />
            </div>
          </div>
        </Box>
      </header>
      <span className="menu_overly" onClick={() => funToggleMenu()}></span>
    </>
  );
};

const HeaderTitle = ({ user, className='' }) => {
  const theme = useTheme();
  return (
    <div className={`right_header_part_title ${className}`}>
      <h1 className="m-0">  <img src={Logo} alt="Mission Control" /> AdSync Dash</h1>
      <Typography
        variant="span"
        sx={{ color: theme.palette.textcolors.headerdatetimeboxright }}
      >
        Hello {user?.first_name}!
      </Typography>
    </div>
  );
};

export default Header;