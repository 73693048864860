import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  InputAdornment, 
  ClickAwayListener,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * SearchableVirtualDropdown - A searchable dropdown menu with standard Material-UI styling
 * Modified to have square styling like other dropdowns in the app
 * 
 * @param {Object} props - Component props
 * @param {Array} props.options - Array of options to display
 * @param {string|null} props.value - Currently selected value
 * @param {Function} props.onChange - Callback when value changes (value) => void
 * @param {string} props.placeholder - Placeholder text for the input
 * @param {boolean} props.disabled - Whether the dropdown is disabled
 * @param {string} props.label - Optional label for the field
 * @param {boolean} props.loading - Whether the dropdown is loading options
 * @param {number} props.maxHeight - Maximum height of the dropdown list (default: 300)
 * @param {Object} props.style - Additional styles for the container
 */
const SearchableVirtualDropdown = ({
  options = [],
  value = null,
  onChange,
  placeholder = "Select an option",
  disabled = false,
  label = "",
  loading = false,
  maxHeight = 300,
  style = {}
}) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [displayedOptions, setDisplayedOptions] = useState([]);
  const [showLimitMessage, setShowLimitMessage] = useState(false);
  const selectRef = useRef(null);
  const searchInputRef = useRef(null);
  
  const MAX_DISPLAYED_OPTIONS = 100;
  
  // Initialize filtered options with all options (limited to maximum)
  useEffect(() => {
    const initialOptions = options || [];
    setFilteredOptions(initialOptions);
    
    // Limit displayed options for performance
    if (initialOptions.length > MAX_DISPLAYED_OPTIONS) {
      setDisplayedOptions(initialOptions.slice(0, MAX_DISPLAYED_OPTIONS));
      setShowLimitMessage(true);
    } else {
      setDisplayedOptions(initialOptions);
      setShowLimitMessage(false);
    }
  }, [options]);
  
  // Filter options based on search term
  const filterOptions = (term) => {
    if (!term) {
      setFilteredOptions(options);
      
      // Limit displayed options for performance
      if (options.length > MAX_DISPLAYED_OPTIONS) {
        setDisplayedOptions(options.slice(0, MAX_DISPLAYED_OPTIONS));
        setShowLimitMessage(true);
      } else {
        setDisplayedOptions(options);
        setShowLimitMessage(false);
      }
      return;
    }
    
    const filtered = options.filter(option => 
      String(option).toLowerCase().includes(term.toLowerCase().trim())
    );
    
    setFilteredOptions(filtered);
    
    // Limit displayed options for performance
    if (filtered.length > MAX_DISPLAYED_OPTIONS) {
      setDisplayedOptions(filtered.slice(0, MAX_DISPLAYED_OPTIONS));
      setShowLimitMessage(true);
    } else {
      setDisplayedOptions(filtered);
      setShowLimitMessage(false);
    }
  };
  
  // Handle search box input change
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterOptions(term);
  };
  
  // Handle direct keyboard input in the Select
  const handleKeyDown = (e) => {
    // If the user is typing and not using control keys (up/down/enter)
    if (e.key.length === 1 || e.key === 'Backspace' || e.key === 'Delete') {
      // If not already open, open the dropdown
      if (!open) {
        setOpen(true);
      }
      
      // Focus the search input if it exists
      if (searchInputRef.current) {
        setTimeout(() => {
          searchInputRef.current.focus();
          
          // If it's a regular character key, add it to the search term
          if (e.key.length === 1) {
            const newSearchTerm = searchTerm + e.key;
            setSearchTerm(newSearchTerm);
            filterOptions(newSearchTerm);
          }
        }, 10);
      }
    }
  };
  
  // Handle option selection
  const handleChange = (e) => {
    onChange(e.target.value);
    setSearchTerm(''); // Clear search on selection
  };
  
  // Custom MenuProps to style the dropdown
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: maxHeight,
        borderRadius: 4,
        marginTop: 8
      }
    },
    MenuListProps: {
      style: {
        padding: 0
      }
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    autoFocus: false,
  };
  
  return (
    <FormControl fullWidth size="small" style={style}>
      <Select
        ref={selectRef}
        value={value || ''}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        displayEmpty
        disabled={disabled}
        MenuProps={MenuProps}
        IconComponent={KeyboardArrowDownIcon}
        onOpen={() => setOpen(true)}
        onClose={() => {
          setOpen(false);
          setSearchTerm('');
          filterOptions('');
        }}
        renderValue={(selected) => {
          if (!selected) {
            return <Typography color="text.secondary">{placeholder}</Typography>;
          }
          return selected;
        }}
        sx={{
          borderRadius: '4px', // Square styling
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.23)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main',
          }
        }}
      >
        {/* Search box at the top of dropdown */}
        <MenuItem 
          dense 
          disableTouchRipple 
          disableRipple
          sx={{ 
            p: 1, 
            borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          <TextField
            fullWidth
            placeholder="Search..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearch}
            onClick={(e) => e.stopPropagation()}
            inputRef={searchInputRef}
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px', // Square styling
              }
            }}
          />
        </MenuItem>
        
        {/* No results message */}
        {filteredOptions.length === 0 && (
          <MenuItem disabled sx={{ color: 'text.secondary', textAlign: 'center', py: 2 }}>
            {loading ? 'Loading options...' : 'No matching options'}
          </MenuItem>
        )}
        
        {/* Limited options message */}
        {showLimitMessage && filteredOptions.length > 0 && (
          <MenuItem 
            disabled 
            sx={{ 
              color: 'text.secondary', 
              textAlign: 'center',
              py: 1,
              fontSize: '0.75rem',
              borderBottom: '1px dashed rgba(255, 255, 255, 0.12)',
            }}
          >
            Showing {displayedOptions.length} of {filteredOptions.length} results. Continue typing to narrow results.
          </MenuItem>
        )}
        
        {/* Options */}
        {displayedOptions.map((option, index) => (
          <MenuItem 
            key={index} 
            value={option}
            sx={{
              borderRadius: 0, // Square styling
              py: 1
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SearchableVirtualDropdown; 