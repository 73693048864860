import React, { useState, useEffect, useMemo } from 'react';
import { Button, Chip, useTheme, Tooltip } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FilterSvg from '../../asset/image/filter.svg';
import SaveIcon from '@mui/icons-material/Save';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import SearchableVirtualDropdown from '../cpa/individual-call/components/SearchableVirtualDropdown';

// State abbreviations for dropdown
const STATE_OPTIONS = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

// Fields with potentially large number of options that should use virtual dropdown
const LARGE_OPTION_FIELDS = [
  'call_id', 'affiliate', 'target', 'buyer_name', 'source', 
  'campaign', 'creative', 'carrier', 'lander_slug', 'agent_name'
];

// Default profile for filters
const DEFAULT_PROFILE = {
  name: 'Default',
  filters: [],
  filterLogic: 'AND'
};

/**
 * Header filter component for secondary analysis
 */
const SecondaryAnalysisFilters = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [localFilters, setLocalFilters] = useState([]);
  // Add state for filter logic
  const [filterLogic, setFilterLogic] = useState('AND');
  
  // States for filter profiles
  const [profiles, setProfiles] = useState([DEFAULT_PROFILE]);
  const [activeProfile, setActiveProfile] = useState('Default');
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [newProfileName, setNewProfileName] = useState('');
  const [validationError, setValidationError] = useState('');
  
  // Add state for storing filter options from the API data
  const [filterOptions, setFilterOptions] = useState({
    call_id: [],
    affiliate: [],
    target: [],
    buyer_name: [],
    source: [],
    campaign: [],
    state: STATE_OPTIONS,
    creative: [],
    carrier: [],
    enrollment_status: ['ENROLLED', 'NOT_ENROLLED'],
    lander_slug: [],
    agent_name: [],
    processing_status: [],
    response_type: []
  });

  // Load filters and profiles from localStorage on component mount
  useEffect(() => {
    try {
      // Load saved profiles first
      const savedFilterProfiles = localStorage.getItem('qa-ai-filter-profiles');
      if (savedFilterProfiles) {
        setProfiles(JSON.parse(savedFilterProfiles));
      } else {
        // If no profiles exist, initialize with just the default profile
        setProfiles([DEFAULT_PROFILE]);
      }
      
      // Then load current configuration
      const savedConfig = localStorage.getItem('qa-ai-secondary-config');
      if (savedConfig) {
        const config = JSON.parse(savedConfig);
        if (config.filters) {
          setFilters(config.filters);
          // If there's a combinator property, use it
          if (config.filterLogic) {
            setFilterLogic(config.filterLogic);
          }
          
          // Find if current config matches any saved profile
          if (profiles.length > 0) {
            const matchingProfile = findMatchingProfile(config.filters, config.filterLogic, profiles);
            if (matchingProfile) {
              setActiveProfile(matchingProfile.name);
            } else {
              setActiveProfile('Custom');
            }
          }
        }
      }
    } catch (error) {
      console.error('Error loading filters from storage:', error);
    }
  }, []);

  // Event listener for receiving filter options from the main component
  useEffect(() => {
    const handleFilterOptionsUpdate = (e) => {
      if (e.detail && e.detail.filterOptions) {
        setFilterOptions(prevOptions => ({
          ...prevOptions,
          ...e.detail.filterOptions
        }));
      }
    };
    
    window.addEventListener('filter-options-update', handleFilterOptionsUpdate);
    
    return () => {
      window.removeEventListener('filter-options-update', handleFilterOptionsUpdate);
    };
  }, []);

  // Function to find if the current filter configuration matches any saved profile
  const findMatchingProfile = (filterConfig, logicConfig, profilesList) => {
    return profilesList.find(profile => {
      // Check if filter logic matches
      if (profile.filterLogic !== logicConfig) return false;
      
      // Must have same number of filters
      if (profile.filters.length !== filterConfig.length) return false;
      
      // Deep compare the filters (this is simplified - a real deep compare would be more robust)
      // For nested objects like groups, a proper deep comparison would be needed
      return JSON.stringify(profile.filters) === JSON.stringify(filterConfig);
    });
  };

  // Open dialog and initialize local filters
  const handleOpen = () => {
    setLocalFilters(filters || []);
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
    setShowSaveDialog(false);
    setValidationError('');
  };
  
  const handleApply = () => {
    setFilters(localFilters);
    
    // Save to localStorage for CallQaAiSecondaryAnalysis to use
    try {
      const savedConfig = localStorage.getItem('qa-ai-secondary-config');
      const config = savedConfig ? JSON.parse(savedConfig) : {};
      config.filters = localFilters;
      config.filterLogic = filterLogic;
      
      // Check if the applied configuration matches any profile
      const matchingProfile = findMatchingProfile(localFilters, filterLogic, profiles);
      const profileName = matchingProfile ? matchingProfile.name : 'Custom';
      setActiveProfile(profileName);
      
      // Store the active profile name
      config.activeFilterProfile = profileName;
      localStorage.setItem('qa-ai-secondary-config', JSON.stringify(config));
      
      // Dispatch a custom event to notify the secondary analysis component
      window.dispatchEvent(new CustomEvent('secondary-filters-changed', { 
        detail: { 
          filters: localFilters,
          filterLogic: filterLogic,
          activeProfile: profileName
        }
      }));
    } catch (error) {
      console.error('Error saving filters to storage:', error);
    }
    
    handleClose();
  };
  
  const handleReset = () => {
    setLocalFilters([]);
    setFilters([]);
    setFilterLogic('AND');
    setActiveProfile('Default');
    
    // Clear filters in localStorage
    try {
      const savedConfig = localStorage.getItem('qa-ai-secondary-config');
      const config = savedConfig ? JSON.parse(savedConfig) : {};
      config.filters = [];
      config.filterLogic = 'AND';
      config.activeFilterProfile = 'Default';
      localStorage.setItem('qa-ai-secondary-config', JSON.stringify(config));
      
      // Notify the secondary analysis component
      window.dispatchEvent(new CustomEvent('secondary-filters-changed', { 
        detail: { 
          filters: [],
          filterLogic: 'AND',
          activeProfile: 'Default'
        }
      }));
    } catch (error) {
      console.error('Error clearing filters in storage:', error);
    }
    
    handleClose();
  };
  
  // Profile handling functions
  const handleOpenSaveDialog = () => {
    // Default new profile name to current active profile if not Custom
    if (activeProfile !== 'Custom') {
      setNewProfileName(activeProfile);
    } else {
      setNewProfileName('');
    }
    setShowSaveDialog(true);
  };
  
  const handleSaveProfile = () => {
    // Validate profile name
    if (!newProfileName.trim()) {
      setValidationError('Profile name cannot be empty');
      return;
    }
    
    // Check for duplicate profile names (except when updating the current profile)
    const isDuplicate = profiles.some(profile => 
      profile.name !== activeProfile && 
      profile.name.toLowerCase() === newProfileName.toLowerCase()
    );
    
    if (isDuplicate) {
      setValidationError('A profile with this name already exists');
      return;
    }
    
    // Create new profile or update existing
    const newProfile = {
      name: newProfileName,
      filters: [...localFilters],
      filterLogic: filterLogic
    };
    
    let updatedProfiles;
    const existingProfileIndex = profiles.findIndex(p => p.name === newProfileName);
    
    if (existingProfileIndex >= 0) {
      // Update existing profile
      updatedProfiles = [...profiles];
      updatedProfiles[existingProfileIndex] = newProfile;
    } else {
      // Add new profile
      updatedProfiles = [...profiles, newProfile];
    }
    
    // Save profiles to localStorage
    try {
      localStorage.setItem('qa-ai-filter-profiles', JSON.stringify(updatedProfiles));
      setProfiles(updatedProfiles);
      setActiveProfile(newProfileName);
      setShowSaveDialog(false);
      setValidationError('');
    } catch (error) {
      console.error('Error saving filter profiles:', error);
      setValidationError('Failed to save profile: ' + error.message);
    }
  };
  
  const handleLoadProfile = (profile) => {
    // Load the selected profile
    setLocalFilters(profile.filters || []);
    setFilterLogic(profile.filterLogic || 'AND');
    setActiveProfile(profile.name);
    
    // Optionally, you can choose to immediately apply the profile
    // without requiring the user to click Apply
    // This provides a better UX for quick switching between profiles
    setFilters(profile.filters || []);
    
    // Save to localStorage
    try {
      const savedConfig = localStorage.getItem('qa-ai-secondary-config');
      const config = savedConfig ? JSON.parse(savedConfig) : {};
      config.filters = profile.filters || [];
      config.filterLogic = profile.filterLogic || 'AND';
      config.activeFilterProfile = profile.name;
      localStorage.setItem('qa-ai-secondary-config', JSON.stringify(config));
      
      // Notify the secondary analysis component of the profile change
      window.dispatchEvent(new CustomEvent('secondary-filters-changed', { 
        detail: { 
          filters: profile.filters || [],
          filterLogic: profile.filterLogic || 'AND',
          activeProfile: profile.name
        }
      }));
    } catch (error) {
      console.error('Error applying filter profile:', error);
    }
  };
  
  const handleDeleteProfile = (profileName) => {
    // Don't allow deleting the Default profile
    if (profileName === 'Default') return;
    
    // Create new profiles array without the deleted profile
    const updatedProfiles = profiles.filter(p => p.name !== profileName);
    
    // Save updated profiles
    try {
      localStorage.setItem('qa-ai-filter-profiles', JSON.stringify(updatedProfiles));
      setProfiles(updatedProfiles);
      
      // If the active profile is deleted, set to Default
      if (activeProfile === profileName) {
        setActiveProfile('Default');
        const defaultProfile = updatedProfiles.find(p => p.name === 'Default');
        if (defaultProfile) {
          setLocalFilters(defaultProfile.filters || []);
          setFilterLogic(defaultProfile.filterLogic || 'AND');
        } else {
          setLocalFilters([]);
          setFilterLogic('AND');
        }
      }
    } catch (error) {
      console.error('Error deleting filter profile:', error);
    }
  };

  // Existing filter functions
  const addFilter = () => {
    // Create filter with default values, using the first available option from filterOptions when possible
    const newFilter = { field: 'revenue', operator: 'hasValue', value: '' };
    
    // If we have filter options for revenue and operator is not hasValue/hasNoValue,
    // and there are available options, use the first one as a default
    if (filterOptions.revenue && filterOptions.revenue.length > 0 && 
        !['hasValue', 'hasNoValue'].includes(newFilter.operator)) {
      newFilter.value = filterOptions.revenue[0];
    }
    
    setLocalFilters([...localFilters, newFilter]);
  };

  // Add function to create a filter group
  const addFilterGroup = () => {
    // Create filter with default values, using the first available option from filterOptions when possible
    const newFilter = { field: 'revenue', operator: 'hasValue', value: '' };
    
    // If we have filter options for revenue and operator is not hasValue/hasNoValue,
    // and there are available options, use the first one as a default
    if (filterOptions.revenue && filterOptions.revenue.length > 0 && 
        !['hasValue', 'hasNoValue'].includes(newFilter.operator)) {
      newFilter.value = filterOptions.revenue[0];
    }
    
    setLocalFilters([
      ...localFilters, 
      { 
        isGroup: true,
        combinator: 'AND',
        filters: [newFilter]
      }
    ]);
  };
  
  const removeFilter = (index) => {
    const newFilters = [...localFilters];
    newFilters.splice(index, 1);
    setLocalFilters(newFilters);
  };

  // Add new function to handle removing a filter within a group
  const removeGroupFilter = (groupIndex, filterIndex) => {
    const newFilters = [...localFilters];
    const group = {...newFilters[groupIndex]};
    
    // Remove the filter from the group
    group.filters.splice(filterIndex, 1);
    
    // If the group has no more filters, remove the group itself
    if (group.filters.length === 0) {
      newFilters.splice(groupIndex, 1);
    } else {
      newFilters[groupIndex] = group;
    }
    
    setLocalFilters(newFilters);
  };

  // Add new function to add a filter to a group
  const addGroupFilter = (groupIndex) => {
    const newFilters = [...localFilters];
    const group = {...newFilters[groupIndex]};
    
    group.filters.push({ field: 'revenue', operator: 'hasValue', value: '' });
    newFilters[groupIndex] = group;
    
    setLocalFilters(newFilters);
  };
  
  const updateFilter = (index, field, value) => {
    const newFilters = [...localFilters];
    newFilters[index][field] = value;
    
    // Special handling for operators that don't need values
    if (['hasValue', 'hasNoValue'].includes(newFilters[index].operator)) {
      newFilters[index].value = '';
    }
    
    // Reset value when changing fields
    if (field === 'field') {
      // Set appropriate default operator for the field
      if (value === 'revenue') {
        newFilters[index].operator = 'hasValue';
      } else if (value === 'duration_seconds') {
        newFilters[index].operator = 'hasValue';
      } else if (['amount_mentioned', 'word_count'].includes(value)) {
        newFilters[index].operator = '>';
      } else if (value === 'enrollment_status') {
        newFilters[index].operator = '=';
        newFilters[index].value = 'ENROLLED';
      } else if (value === 'state') {
        newFilters[index].operator = '=';
        newFilters[index].value = STATE_OPTIONS[0];
      } else {
        newFilters[index].operator = '=';
      }
      newFilters[index].value = value === 'enrollment_status' ? 'ENROLLED' : '';
    }
    
    setLocalFilters(newFilters);
  };

  // Add new function to update a filter within a group
  const updateGroupFilter = (groupIndex, filterIndex, field, value) => {
    const newFilters = [...localFilters];
    const group = {...newFilters[groupIndex]};
    const filter = {...group.filters[filterIndex]};
    
    filter[field] = value;
    
    // Special handling for operators that don't need values
    if (['hasValue', 'hasNoValue'].includes(filter.operator)) {
      filter.value = '';
    }
    
    // Reset value when changing fields
    if (field === 'field') {
      // Set appropriate default operator for the field
      if (value === 'revenue') {
        filter.operator = 'hasValue';
      } else if (value === 'duration_seconds') {
        filter.operator = 'hasValue';
      } else if (['amount_mentioned', 'word_count'].includes(value)) {
        filter.operator = '>';
      } else if (value === 'enrollment_status') {
        filter.operator = '=';
        filter.value = 'ENROLLED';
      } else if (value === 'state') {
        filter.operator = '=';
        filter.value = STATE_OPTIONS[0];
      } else {
        filter.operator = '=';
      }
      filter.value = value === 'enrollment_status' ? 'ENROLLED' : '';
    }
    
    group.filters[filterIndex] = filter;
    newFilters[groupIndex] = group;
    
    setLocalFilters(newFilters);
  };

  // Add function to update group combinator
  const updateGroupCombinator = (groupIndex, value) => {
    const newFilters = [...localFilters];
    const group = {...newFilters[groupIndex]};
    
    group.combinator = value;
    newFilters[groupIndex] = group;
    
    setLocalFilters(newFilters);
  };
  
  // Get field-specific options
  const getOperatorOptions = (field) => {
    // Financial/numeric fields - expanded options for revenue
    if (['revenue'].includes(field)) {
      return [
        { value: 'hasValue', label: 'has revenue (> 0)' },
        { value: 'hasNoValue', label: 'has no revenue (0 or empty)' },
        { value: '=', label: 'equals' },
        { value: '!=', label: 'does not equal' },
        { value: '>', label: 'greater than' },
        { value: '<', label: 'less than' },
        { value: '>=', label: 'greater than or equal to' },
        { value: '<=', label: 'less than or equal to' }
      ];
    }
    
    // Duration fields - expanded options
    if (['duration_seconds'].includes(field)) {
      return [
        { value: 'hasValue', label: 'has duration (> 0)' },
        { value: 'hasNoValue', label: 'has no duration (0 or empty)' },
        { value: '=', label: 'equals' },
        { value: '!=', label: 'does not equal' },
        { value: '>', label: 'greater than' },
        { value: '<', label: 'less than' },
        { value: '>=', label: 'greater than or equal to' },
        { value: '<=', label: 'less than or equal to' }
      ];
    }
    
    // Other financial/numeric fields
    if (['amount_mentioned', 'word_count'].includes(field)) {
      return [
        { value: 'hasValue', label: 'has value' },
        { value: 'hasNoValue', label: 'has no value' },
        { value: '>', label: 'greater than' },
        { value: '<', label: 'less than' },
        { value: '=', label: 'equals' },
        { value: '!=', label: 'not equals' },
        { value: '>=', label: 'greater than or equal to' },
        { value: '<=', label: 'less than or equal to' }
      ];
    }
    
    // Identifier/exact match fields
    if (['call_id', 'client_phone', 'ani_inbound'].includes(field)) {
      return [
        { value: '=', label: 'equals' },
        { value: '!=', label: 'not equals' }
      ];
    }
    
    // Enrollment status specific
    if (field === 'enrollment_status') {
      return [
        { value: '=', label: 'equals' },
        { value: '!=', label: 'not equals' }
      ];
    }
    
    // State specific
    if (field === 'state') {
      return [
        { value: '=', label: 'equals' },
        { value: '!=', label: 'not equals' }
      ];
    }
    
    // Text content fields that benefit from contains operator
    if (['customer_intent', 'agent_intent', 'intentions', 'triggers', 
         'ad_quote', 'enrollment_reason', 'non_enrollment_reason', 
         'potential_fraud', 'agent_speaker', 'caller_speaker'].includes(field)) {
      return [
        { value: 'hasValue', label: 'has content' },
        { value: 'hasNoValue', label: 'is empty' },
        { value: '=', label: 'equals' },
        { value: '!=', label: 'not equals' },
        { value: 'contains', label: 'contains' },
        { value: 'doesNotContain', label: 'does not contain' }
      ];
    }
    
    // Default options for all other fields
    return [
      { value: '=', label: 'equals' },
      { value: '!=', label: 'not equals' },
      { value: 'contains', label: 'contains' },
      { value: 'doesNotContain', label: 'does not contain' }
    ];
  };
  
  // Get appropriate value input for the field and operator
  const renderValueInput = (filter, index, isInGroup = false, groupIndex = null, filterIndex = null) => {
    const { field, operator, value } = filter;
    
    // Operators that don't need values
    if (['hasValue', 'hasNoValue'].includes(operator)) {
      return null;
    }
    
    // Enrollment status specific dropdown
    if (field === 'enrollment_status') {
      return (
        <FormControl fullWidth size="small">
          <Select
            value={value || 'ENROLLED'}
            onChange={(e) => {
              if (isInGroup) {
                updateGroupFilter(groupIndex, filterIndex, 'value', e.target.value);
              } else {
                updateFilter(index, 'value', e.target.value);
              }
            }}
          >
            <MenuItem value="ENROLLED">ENROLLED</MenuItem>
            <MenuItem value="NOT_ENROLLED">NOT_ENROLLED</MenuItem>
          </Select>
        </FormControl>
      );
    }
    
    // State dropdown
    if (field === 'state') {
      return (
        <FormControl fullWidth size="small">
          <Select
            value={value || STATE_OPTIONS[0]}
            onChange={(e) => {
              if (isInGroup) {
                updateGroupFilter(groupIndex, filterIndex, 'value', e.target.value);
              } else {
                updateFilter(index, 'value', e.target.value);
              }
            }}
          >
            {STATE_OPTIONS.map(state => (
              <MenuItem key={state} value={state}>{state}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    
    // Use virtualized dropdown for fields with potentially large number of options
    if (LARGE_OPTION_FIELDS.includes(field) && filterOptions[field] && filterOptions[field].length > 0) {
      const options = filterOptions[field] || [];
      
      // If there are too many options (>100), use virtualized dropdown
      if (options.length > 100) {
        return (
          <SearchableVirtualDropdown
            options={options}
            value={value}
            onChange={(newValue) => {
              if (isInGroup) {
                updateGroupFilter(groupIndex, filterIndex, 'value', newValue);
              } else {
                updateFilter(index, 'value', newValue);
              }
            }}
            placeholder={`Select ${field.replace(/_/g, ' ')}`}
            maxHeight={250}
          />
        );
      }
      
      // For smaller option sets, still use regular Select for consistency
      return (
        <FormControl fullWidth size="small">
          <Select
            value={value || ''}
            onChange={(e) => {
              if (isInGroup) {
                updateGroupFilter(groupIndex, filterIndex, 'value', e.target.value);
              } else {
                updateFilter(index, 'value', e.target.value);
              }
            }}
            displayEmpty
          >
            <MenuItem value="" disabled><em>Select {field.replace(/_/g, ' ')}</em></MenuItem>
            {options.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    
    // Dropdown for fields with available options that aren't large enough to need virtualization
    if (filterOptions[field] && filterOptions[field].length > 0 && 
        ['processing_status', 'response_type'].includes(field)) {
      return (
        <FormControl fullWidth size="small">
          <Select
            value={value || ''}
            onChange={(e) => {
              if (isInGroup) {
                updateGroupFilter(groupIndex, filterIndex, 'value', e.target.value);
              } else {
                updateFilter(index, 'value', e.target.value);
              }
            }}
            displayEmpty
          >
            <MenuItem value="" disabled><em>Select {field.replace(/_/g, ' ')}</em></MenuItem>
            {filterOptions[field].map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    
    // Revenue field - with $ prefix
    if (field === 'revenue') {
      return (
        <TextField
          size="small"
          type="number"
          value={value}
          onChange={(e) => {
            if (isInGroup) {
              updateGroupFilter(groupIndex, filterIndex, 'value', e.target.value);
            } else {
              updateFilter(index, 'value', e.target.value);
            }
          }}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          placeholder="Enter amount"
        />
      );
    }
    
    // Duration field - with seconds label
    if (field === 'duration_seconds') {
      return (
        <TextField
          size="small"
          type="number"
          value={value}
          onChange={(e) => {
            if (isInGroup) {
              updateGroupFilter(groupIndex, filterIndex, 'value', e.target.value);
            } else {
              updateFilter(index, 'value', e.target.value);
            }
          }}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">seconds</InputAdornment>,
          }}
          placeholder="Enter duration"
        />
      );
    }
    
    // Other numeric fields
    if (['amount_mentioned', 'word_count'].includes(field)) {
      return (
        <TextField
          size="small"
          type="number"
          value={value}
          onChange={(e) => {
            if (isInGroup) {
              updateGroupFilter(groupIndex, filterIndex, 'value', e.target.value);
            } else {
              updateFilter(index, 'value', e.target.value);
            }
          }}
          fullWidth
        />
      );
    }
    
    // Default text input for all other fields
    return (
      <TextField
        size="small"
        value={value}
        onChange={(e) => {
          if (isInGroup) {
            updateGroupFilter(groupIndex, filterIndex, 'value', e.target.value);
          } else {
            updateFilter(index, 'value', e.target.value);
          }
        }}
        fullWidth
      />
    );
  };

  // Add method to render a filter row
  const renderFilterRow = (filter, index, isInGroup = false, groupIndex = null, filterIndex = null) => {
    return (
      <Box key={`${isInGroup ? `group-${groupIndex}-filter-${filterIndex}` : `filter-${index}`}`} mb={2} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <FormControl size="small" sx={{ minWidth: 150 }}>
          <Select
            value={filter.field}
            onChange={(e) => {
              if (isInGroup) {
                updateGroupFilter(groupIndex, filterIndex, 'field', e.target.value);
              } else {
                updateFilter(index, 'field', e.target.value);
              }
            }}
          >
            {/* Basic Text/String Filters */}
            <MenuItem disabled value=""><em>Basic Filters</em></MenuItem>
            <MenuItem value="call_id">Call ID {filterOptions.call_id.length > 0 && `(${filterOptions.call_id.length})`}</MenuItem>
            <MenuItem value="affiliate">Affiliate {filterOptions.affiliate.length > 0 && `(${filterOptions.affiliate.length})`}</MenuItem>
            <MenuItem value="target">Target {filterOptions.target.length > 0 && `(${filterOptions.target.length})`}</MenuItem>
            <MenuItem value="buyer_name">Buyer Name {filterOptions.buyer_name.length > 0 && `(${filterOptions.buyer_name.length})`}</MenuItem>
            <MenuItem value="source">Source {filterOptions.source.length > 0 && `(${filterOptions.source.length})`}</MenuItem>
            <MenuItem value="campaign">Campaign {filterOptions.campaign.length > 0 && `(${filterOptions.campaign.length})`}</MenuItem>
            <MenuItem value="state">State {filterOptions.state.length > 0 && `(${filterOptions.state.length})`}</MenuItem>
            <MenuItem value="creative">Creative {filterOptions.creative.length > 0 && `(${filterOptions.creative.length})`}</MenuItem>
            <MenuItem value="carrier">Carrier {filterOptions.carrier.length > 0 && `(${filterOptions.carrier.length})`}</MenuItem>
            <MenuItem value="enrollment_status">Enrollment Status</MenuItem>
            <MenuItem value="lander_slug">Lander Slug {filterOptions.lander_slug.length > 0 && `(${filterOptions.lander_slug.length})`}</MenuItem>
            <MenuItem value="agent_name">Agent Name {filterOptions.agent_name.length > 0 && `(${filterOptions.agent_name.length})`}</MenuItem>
            <MenuItem value="end_buyer">End Buyer</MenuItem>
            
            {/* Numeric/Financial Filters */}
            <MenuItem disabled value=""><em>Financial Metrics</em></MenuItem>
            <MenuItem value="revenue">Revenue</MenuItem>
            <MenuItem value="duration_seconds">Duration (seconds)</MenuItem>
            <MenuItem value="amount_mentioned">Amount Mentioned</MenuItem>
            <MenuItem value="word_count">Word Count</MenuItem>
            
            {/* Call Outcome Filters */}
            <MenuItem disabled value=""><em>Call Outcomes</em></MenuItem>
            <MenuItem value="enrollment_reason">Enrollment Reason</MenuItem>
            <MenuItem value="non_enrollment_reason">Non-Enrollment Reason</MenuItem>
            <MenuItem value="plan_enrolled">Plan Enrolled</MenuItem>
            <MenuItem value="call_ended_by">Call Ended By</MenuItem>
            <MenuItem value="customer_outcome">Customer Outcome</MenuItem>
            <MenuItem value="agent_outcome">Agent Outcome</MenuItem>
            
            {/* Intent & Behavior Filters */}
            <MenuItem disabled value=""><em>Intent & Behavior</em></MenuItem>
            <MenuItem value="customer_intent">Customer Intent</MenuItem>
            <MenuItem value="agent_intent">Agent Intent</MenuItem>
            <MenuItem value="intentions">Intentions</MenuItem>
            <MenuItem value="triggers">Triggers</MenuItem>
            <MenuItem value="potential_fraud">Potential Fraud</MenuItem>
            
            {/* Additional Metadata */}
            <MenuItem disabled value=""><em>Other Data</em></MenuItem>
            <MenuItem value="ani_inbound">ANI Inbound</MenuItem>
            <MenuItem value="client_phone">Client Phone</MenuItem>
            <MenuItem value="hangup_direction">Hangup Direction</MenuItem>
            <MenuItem value="status">Status</MenuItem>
            <MenuItem value="caller_name">Caller Name</MenuItem>
            <MenuItem value="agent_speaker">Agent Speaker</MenuItem>
            <MenuItem value="caller_speaker">Caller Speaker</MenuItem>
            <MenuItem value="ad_quote">Ad Quote</MenuItem>
            <MenuItem value="ad_source">Ad Source</MenuItem>
            <MenuItem value="device_used">Device Used</MenuItem>
          </Select>
        </FormControl>
        
        <FormControl size="small" sx={{ minWidth: 150 }}>
          <Select
            value={filter.operator}
            onChange={(e) => {
              if (isInGroup) {
                updateGroupFilter(groupIndex, filterIndex, 'operator', e.target.value);
              } else {
                updateFilter(index, 'operator', e.target.value);
              }
            }}
          >
            {getOperatorOptions(filter.field).map(op => (
              <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        
        {renderValueInput(filter, index, isInGroup, groupIndex, filterIndex)}
        
        <IconButton 
          onClick={() => {
            if (isInGroup) {
              removeGroupFilter(groupIndex, filterIndex);
            } else {
              removeFilter(index);
            }
          }}
          size="small"
          sx={{ color: 'white' }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    );
  };
  
  // Add method to render a filter group
  const renderFilterGroup = (group, index) => {
    return (
      <Paper 
        key={`group-${index}`} 
        elevation={0} 
        sx={{ 
          p: 2, 
          mb: 2, 
          bgcolor: 'rgba(40, 41, 58, 0.95)', 
          borderRadius: '8px', 
          border: '1px dashed rgba(255, 255, 255, 0.2)',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <FormControl size="small">
            <Select
              value={group.combinator || 'AND'}
              onChange={(e) => updateGroupCombinator(index, e.target.value)}
              sx={{ minWidth: 100 }}
            >
              <MenuItem value="AND">AND</MenuItem>
              <MenuItem value="OR">OR</MenuItem>
            </Select>
          </FormControl>
          <Box>
            <Button 
              startIcon={<AddIcon />} 
              variant="outlined" 
              size="small"
              onClick={() => addGroupFilter(index)}
            >
              Add Filter
            </Button>
          </Box>
        </Box>
        
        {group.filters.map((filter, filterIndex) => 
          renderFilterRow(filter, null, true, index, filterIndex)
        )}

        {group.filters.length === 0 && (
          <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'center', my: 2 }}>
            Group is empty. Click "Add Filter" to create a filter.
          </Typography>
        )}
      </Paper>
    );
  };
  
  // Check for existence of "Has No Revenue" filter
  const hasNoRevenueFilters = useMemo(() => {
    if (!filters || filters.length === 0) return 0;
    
    // Count filters and filters in groups
    const countNoRevenueFilters = (items) => {
      return items.reduce((count, item) => {
        if (item.isGroup && item.filters) {
          // Count no-revenue filters within the group
          return count + countNoRevenueFilters(item.filters);
        }
        // Check if it's a "Has No Revenue" filter
        if (item.field === 'revenue' && item.operator === 'hasNoValue') {
          return count + 1;
        }
        return count;
      }, 0);
    };
    
    return countNoRevenueFilters(filters);
  }, [filters]);
  
  // Should show filter chips?
  const shouldShowFilterChips = useMemo(() => {
    return (
      filters && filters.length > 0 || 
      activeProfile !== 'Default' && activeProfile !== 'Custom' ||
      hasNoRevenueFilters > 0
    );
  }, [filters, activeProfile, hasNoRevenueFilters]);

  return (
    <>
      <div className="DownloadReport">
        <Tooltip title="Filter calls" arrow>
          <Button
            onClick={handleOpen}
            startIcon={<FilterListIcon />}
            size="large"
            sx={{
              color: theme.palette.textcolors.headerplaningitemtext,
              border: theme.palette.borderClr.reportDownload,
              backgroundColor: theme.palette.background.reportDownload,
              height: '100%',
              ...(shouldShowFilterChips && {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main
              }),
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            Filters
            {shouldShowFilterChips && (
              <Chip 
                label={filters.length} 
                size="small" 
                color="primary" 
                sx={{ ml: 1, height: 20, minWidth: 20 }}
              />
            )}
          </Button>
        </Tooltip>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: { backgroundColor: theme.palette.background.default },
        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Call Performance Filters</Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent>
          {/* Profile Management Section */}
          <Box sx={{ mb: 3, mt: 1 }}>
            <Typography variant="subtitle2" sx={{ mb: 1, color: 'rgba(255, 255, 255, 0.7)' }}>
              Filter Profiles
            </Typography>
            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
              {profiles.map(profile => (
                <Chip
                  key={profile.name}
                  label={profile.name}
                  onClick={() => handleLoadProfile(profile)}
                  onDelete={profile.name !== 'Default' ? () => handleDeleteProfile(profile.name) : undefined}
                  color={activeProfile === profile.name ? 'primary' : 'default'}
                  variant={activeProfile === profile.name ? 'filled' : 'outlined'}
                  sx={{ 
                    fontWeight: activeProfile === profile.name ? 'bold' : 'normal',
                    bgcolor: activeProfile === profile.name ? 'primary.main' : 'rgba(255,255,255,0.1)'
                  }}
                />
              ))}
              
              <Button 
                variant="outlined" 
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleOpenSaveDialog}
              >
                SAVE CURRENT
              </Button>
            </Box>
          </Box>
          
          <Divider sx={{ mb: 2 }} />
          
          {/* Main filter editing section */}
          <Paper 
            elevation={0}
            sx={{ 
              p: 2, 
              bgcolor: 'rgba(30, 31, 48, 0.95)', 
              borderRadius: '8px', 
              color: 'white',
              mb: 2
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <FormControl size="small">
                <Select
                  value={filterLogic}
                  onChange={(e) => setFilterLogic(e.target.value)}
                  sx={{ minWidth: 100 }}
                >
                  <MenuItem value="AND">AND</MenuItem>
                  <MenuItem value="OR">OR</MenuItem>
                </Select>
              </FormControl>
              <Box>
                <Button 
                  startIcon={<AddIcon />} 
                  variant="outlined" 
                  size="small"
                  onClick={addFilter}
                  sx={{ mr: 1 }}
                >
                  Add Filter
                </Button>
                <Button 
                  variant="outlined" 
                  size="small"
                  onClick={addFilterGroup}
                >
                  + Group
                </Button>
              </Box>
            </Box>
            
            {localFilters.map((item, index) => 
              item.isGroup 
                ? renderFilterGroup(item, index)
                : renderFilterRow(item, index)
            )}
            
          </Paper>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={handleReset} color="error" variant="outlined">
            Reset
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleApply} color="primary" variant="contained" autoFocus>
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Save Profile Dialog */}
      <Dialog
        open={showSaveDialog}
        onClose={() => {
          setShowSaveDialog(false);
          setValidationError('');
        }}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          style: { backgroundColor: theme.palette.background.default },
        }}
      >
        <DialogTitle>Save Filter Profile</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Profile Name"
            fullWidth
            variant="outlined"
            value={newProfileName}
            onChange={(e) => setNewProfileName(e.target.value)}
            error={!!validationError}
            helperText={validationError}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSaveProfile();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setShowSaveDialog(false);
            setValidationError('');
          }}>
            Cancel
          </Button>
          <Button onClick={handleSaveProfile} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SecondaryAnalysisFilters; 