import { lazy } from "react";

const LoginPage = lazy(() => import("../pages/Login"));
const RegisterPage = lazy(() => import("../pages/Signup"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Pnl = lazy(() => import("../pages/Pnl"));
const Trends = lazy(() => import("../pages/Trends"));
const CreateUser = lazy(() => import("../components/users/CreateUser"));
const UserDetail = lazy(() => import("../components/users/UserDetails"));
const UpdateUser = lazy(() => import("../components/users/UpdateUser"));
const User = lazy(() => import("../pages/Users"));
const AdAccountList = lazy(() => import("../pages/AdAccountList"));
const CPAList = lazy(() => import("../pages/CPA"));
const Profile = lazy(() => import("../pages/Profile"));
const Status = lazy(() => import("../pages/Status"));
const CallQaAiDash = lazy(() => import("../components/cpa/CallQaAiDash"));
const CallQaAiCsvImport = lazy(() => import("../components/cpa/csv-import/CallQaAiCsvImport"));
const CallQaAiCsvTest = lazy(() => import("../components/cpa/csv-import/CallQaAiCsvTest"));
const CsvDataDisplay = lazy(() => import("../components/cpa/csv-import/components/CallQaAiCsvPreviousUploads"));
const CallQaAiSecondaryAnalysis = lazy(() => import("../components/cpa/individual-call/CallQaAiSecondaryAnalysis"));
const CallQaAiIndividualCall = lazy(() => import("../components/cpa/individual-call/[id]/CallQaAiIndividualCall"));
const CallQaAiPlayground = lazy(() =>
  import("../components/cpa/playground/CallQaAiPlayground")
);
const CallQaAiStatistics = lazy(() =>
  import("../components/cpa/playground/CallQaAiStatistics")
);
const CallQaAiTraining = lazy(() =>
  import("../components/cpa/training/CallQaAiTraining")
);
const CallQaAiRingbaCalls = lazy(() =>
  import("../components/cpa/ringba/CallQaAiRingbaCalls")
);
const CallQaAiPrompts = lazy(() =>
  import("../components/cpa/prompt/CallQaAiPrompts")
);
const PnlAds = lazy(() => import("../pages/PnlAds"));
const PnlAdsByCreative = lazy(() => import("../pages/PnlAdsByCreative"));
const PnlLanders = lazy(() => import("../pages/PnlLanders"));
const PnlAdsByCreativeLayout = lazy(() =>
  import("../pages/PnlAdsByCreativeLayout")
);
const PnlCreativeDetails = lazy(() =>
  import("../components/pnlCreatives/PnlCreativeDetails")
);
const CreativeComparison = lazy(() =>
  import("../components/pnlCreatives/CreativesComparison")
);
const PnlCreativeAds = lazy(() =>
  import("../components/pnlCreatives/PnlCreativeAds")
);
const PnlAdsCreativeAdGroup = lazy(() =>
  import("../components/pnlCreatives/PnlCreativeAdGroup")
);
const PnlCreative = lazy(() =>
  import("../components/pnlCreatives/PnlCreative")
);
const StateView = lazy(() => import("../components/pnlCreatives/StateView"));
const SecretSauceView = lazy(() => import("../pages/SauceView"));
const PnlLayout = lazy(() => import("../pages/PnlLayout"));
const DragAndDropLayout = lazy(() =>
  import("../components/LanderFlowChart/DragAndDrop")
);
const ViewDragAndDrop = lazy(() =>
  import("../components/LanderFlowChart/ViewDragAndDrop")
);
const LanderFlowChartLayout = lazy(() =>
  import("../pages/LanderFlowChartLayout")
);
const LanderFlowChart = lazy(() =>
  import("../components/LanderFlowChart/LanderFlowChart")
);
const CreatePreset = lazy(() => import("../components/presetColumn/CreatePreset"));


const PresetManage = lazy(() => import("../pages/PresetManage"));

export const publicRoutes = [
  { path: "/", component: LoginPage },
  { path: "/signup", component: RegisterPage },
  { path: "/forgot-password", component: ForgotPassword }
];

export const privateRoutes = [
  { path: "/dashboard", component: Dashboard },
  // { path: "/pnl", component: Pnl },
  { path: "/pnl", component: PnlLayout },
  { path: "/trends", component: Trends },
  { path: "/users", component: User },
  { path: "/create-user", component: CreateUser },
  { path: "/update-user", component: UpdateUser },
  { path: "/user-detail", component: UserDetail },
  { path: "/ad-accounts", component: AdAccountList },
  { path: "/cpa", component: CPAList },
  { path: "/qa-ai/dashboard", component: CallQaAiDash },
  { path: "/qa-ai/playground", component: CallQaAiPlayground },
  { path: "/qa-ai/training", component: CallQaAiTraining },
  { path: "/qa-ai/ringba-calls", component: CallQaAiRingbaCalls },
  { path: "/qa-ai/statistics", component: CallQaAiStatistics },
  { path: "/qa-ai/prompts", component: CallQaAiPrompts },
  { path: "/qa-ai/csv-import", component: CallQaAiCsvImport },
  { path: "/qa-ai/csv-test-results", component: CallQaAiCsvTest },
  { path: "/qa-ai/csv-data", component: CsvDataDisplay },
  { path: "/qa-ai/individual-call/:id", component: CallQaAiIndividualCall },
  { path: "/qa-ai/secondary-analysis", component: CallQaAiSecondaryAnalysis },
  { path: "/profile", component: Profile },
  // { path: "/pnl-ads", component: PnlAds },
  // { path: "/pnl-creative", component: PnlAdsByCreative },
  { path: "/status", component: Status },
  { path: "/pnl-landers", component: PnlLanders },
  {
    path: "/pnl-creative",
    component: PnlAdsByCreativeLayout,
    children: [
      { path: "", component: PnlCreative },
      { path: "state-view/:creatives", component: StateView },
      { path: "state-view", component: StateView },
      { path: "ads", component: PnlCreativeAds },
      { path: "ad-groups", component: PnlAdsCreativeAdGroup },
      { path: "secret-sauce", component: SecretSauceView },
      { path: "creative-details/:id", component: PnlCreativeDetails },
      { path: "creative-comparison/:ids", component: CreativeComparison },
    ],
  },
  {
    path:"/column-settings",
    component:PresetManage
   },
   {
    path:"/preset/:id",
    component:CreatePreset
   },
  {
   path:"/create-preset",
   component:CreatePreset
  }
  //{
    // path:"/lander-flow-chart",component:LanderFlowChartLayout,
    // children:[
    //   {path:"",component:LanderFlowChart},
    //   {path:":id/:landerSlug",component:ViewDragAndDrop},
    //   {path:"diagram/:id",component:DragAndDropLayout}
    // ]
  // },
];
