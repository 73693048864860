import baseApi from "../api.js";
import { API_ENDPOINTS } from "../endpoints.js";

export const campaignApi = {
  getPnlsCampaigns: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_PNLS, { params }),
  getTrendCampaigns: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_TRENDS, { params }),
  getPnlAds: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_PNL_ADS, { params }),
  getPnlAccount: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_PNL_ACCOUNT, { params }),
  getLanderCreativeCombo: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_CREATIVE_COMBO, { params }),
  getCreativePnl: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_PNL, { params }),
  // updateFilterPnlAdsByCreative: (params, data) =>
  //   baseApi.post(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_PNL, data, { params }),
  getFilterPnlAdsBylander: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_PNL, { params }),
  getLanderlandingPage: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_LANDING_PAGE, { params }),
  getUniqueVerticals: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_UNIQUE_VERTICALS, { params }),
  getUniqueTrafficPlatforms: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_UNIQUE_TRAFFIC_PLATFORMS, {
      params,
    }),
  getUniqueTrafficPlatformSources: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_UNIQUE_TRAFFIC_PLATFORM_SOURCES, {
      params,
    }),
  getUniqueAgencies: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_UNIQUE_AGENCIES, { params }),
  getUniqueMediaBuyers: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_UNIQUE_MEDIA_BUYERS, { params }),
  getMetrics: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_METRICS, { params }),
  getHourlyTrends: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_HOURLY_TRENDS, { params }),
  getMediaBuyers: () => baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_MEDIA_BUYERS),
  getVerticals: () => baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_VERTICALS),
  updateCampaignBudget: (data) =>
    baseApi.put(API_ENDPOINTS.CAMPAIGN.UPDATE_CAMPAIGN_BUDGET, data),
  updateCampaignStatus: (data) =>
    baseApi.put(API_ENDPOINTS.CAMPAIGN.UPDATE_CAMPAIGN_STATUS, data),
  uploadCampaignCsv: (data) =>
    baseApi.post(API_ENDPOINTS.CAMPAIGN.UPLOAD_CAMPAIGN_CSV, data),
  updateCampaignsManually: (data) =>
    baseApi.put(API_ENDPOINTS.CAMPAIGN.UPDATE_CAMPAIGNS_MANUALLY, data),
  getCreativeStateWiseData: (data) =>
    baseApi.post(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_STATE_WISE_DATA, data),
  getCreativeStateData: (data) =>
    baseApi.post(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_STATE_DATA, data),
  updateCampaignsConfig: (data) =>
    baseApi.put(API_ENDPOINTS.CAMPAIGN.UPDATE_CAMPAIGNS_CONFIG, data),
  getCreativeRankings: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_RANKINGS, { params }),
  getCreativeRankingsComparison: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_COMPARISON, { params }),
  getCreativesSauceView: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_SAUCE_VIEW, { params }),
  getLanderSauceView: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_SAUCE_VIEW, { params }),
  getLanderSauceViewCharts: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_SAUCE_VIEW_CHARTS, {
      params,
    }),
  getLanderRankingsComparison: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_COMPARISON, { params }),
  getLanderDetails: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_DETAILS, { params }),
  getLanderFCDomains: ({ lander, ...params }) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_FC_DOMAINS(lander), {
      params,
    }),
  getAssociatedAds: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_PNL_ASSOCIATED_ADS, {
      params,
    }),
  getCfCampaigns: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_CF_CAMPAIGNS, { params }),
  getCfLander: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_CF_LANDER, { params }),
  getLanderFlowDiagramList: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_FLOW_CHART, { params }),
  getLanderDomainsList: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_DOMAINS, { params }),
  getLanderDomainsDNS: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_DOMAINS_DNS, { params }),
  createLanderFlowChart: (data) =>
    baseApi.post(API_ENDPOINTS.CAMPAIGN.GET_LANDER_FLOW_CHART, data),
  domainDNSRegister: (data) =>
    baseApi.post(API_ENDPOINTS.CAMPAIGN.DOMAIN_DNS_REGISTER, data),
  createLanderDomains: (data) =>
    baseApi.post(API_ENDPOINTS.CAMPAIGN.CREATE_LANDER_DOMAINS, data),

  updateLanderFlowChart: (id, data) =>
    baseApi.put(`${API_ENDPOINTS.CAMPAIGN.GET_LANDER_FLOW_CHART}/${id}`, data),
  deleteLanderFlowChart: (id) =>
    baseApi.delete(`${API_ENDPOINTS.CAMPAIGN.GET_LANDER_FLOW_CHART}/${id}`),
  getLanderFlowChartById: (id) =>
    baseApi.get(`${API_ENDPOINTS.CAMPAIGN.GET_LANDER_FLOW_CHART}/${id}`),
  searchLanderDomains: (name) =>
    baseApi.get(`${API_ENDPOINTS.CAMPAIGN.SEARCH_LANDER_DOMAINS}/${name}`),
  getLanderFlowDiagramClick: (lander, params) =>
    baseApi.get(
      `${API_ENDPOINTS.CAMPAIGN.GET_LANDER_FLOW_CHART_CLICK}/${lander}`,
      { params }
    ),
  getPnlAdGroup: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_PNL_AD_GROUP, { params }),
  createPreset: (data) =>
    baseApi.post(API_ENDPOINTS.CAMPAIGN.PRESETS, data),
  getPresetById:(id) =>
    baseApi.get(`${API_ENDPOINTS.CAMPAIGN.PRESETS}/${id}`),
  getPreset:() =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.PRESETS),
  getPresetManage:() =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.PRESETS_MANAGE),
  presetAllColumn:() =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.PRESETS_ALL_COLUMN),
  updatePreset: (id, data) =>
    baseApi.put(`${API_ENDPOINTS.CAMPAIGN.PRESETS}/${id}`, data),
  deletePresetColumn: (id) =>
    baseApi.delete(`${API_ENDPOINTS.CAMPAIGN.PRESETS}/${id}`),
  createNote: (data) =>
    baseApi.post(API_ENDPOINTS.CAMPAIGN.CREATE_NOTE, data),
  getAllLogs: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_ALL_LOGS, { params }),
  getPnlsCampaignsByMediaBuyer: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_PNLS_BY_MEDIA, { params }),
  getCampaignDetail: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_CAMPAIGN_DETAIL, { params }),
  getLeadsGen: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LEADS_GEN, { params }),
};
