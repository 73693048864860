import React, { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Box, Collapse, IconButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Close, FilterAlt } from "@mui/icons-material";
import { Button, CircularProgress, useTheme, useMediaQuery } from "@mui/material";
import { useGetUniqueVerticals, useGetUniqueTrafficPlatformSources, useGetUniqueAgencies, useGetUniqueMediaBuyers } from '../../hooks/api/useCampaign';
import { capitalizeFirstLetter } from '../../utils/stringUtils';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function FilterBar({ onClose }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedFilters, setSelectedFilters] = useState({});
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { data: verticals, isLoading: isLoadingVerticals } = useGetUniqueVerticals();
    const { data: trafficPlatformSources, isLoading: isLoadingTrafficPlatformSources } = useGetUniqueTrafficPlatformSources();
    const { data: agencies, isLoading: isLoadingAgencies } = useGetUniqueAgencies();
    const { data: mediaBuyers, isLoading: isLoadingMediaBuyers } = useGetUniqueMediaBuyers();
    const [agencyList,setAgencyList] = useState([]);
    const [filterCategories, setFilterCategories] = useState([]);

    useEffect(() => {
        if (verticals && trafficPlatformSources && agencies && mediaBuyers) {
            let categories = [
                {
                    name: "media_buyer",
                    label: "Media Buyer",
                    options: mediaBuyers.data.data
                },
                {
                    name: "vertical",
                    label: "Verticals",
                    options: verticals.data.data
                }
            ];
            if(agencies && agencies.data.data.length > 0){
                const agencyList = [];
                const googleAgency = [];
                for(let i of agencies.data.data){
                  if(i.trafficPlatformName === 'google'){
                    googleAgency.push({id:i.id,name:i.agencyName});
                    agencyList.push({id:i.id,name:i.agencyName,hide:true})
                  }
                  else{
                    agencyList.push({...i,name:i.agencyName,hide:false});
                  }
                }
                setAgencyList(googleAgency);
                if(googleAgency.length > 0){
                    agencyList.unshift({ name:'Google',child:googleAgency,hide:false})
                }
                categories.push({
                    name: "agency",
                    label: "Agency",
                    options: agencyList
                })
            }
            
            if (location.pathname === '/pnl') {
                categories.push({
                    name: "roi_filter",
                    label: "Profitability",
                    options: [
                        { id: "positive", name: "Positive" },
                        { id: "negative", name: "Negative" }
                    ]
                });
            }

            setFilterCategories(categories);
        }
    }, [verticals, trafficPlatformSources, agencies, mediaBuyers, location.pathname]);

    useEffect(() => {
        const filtersObject = {};
        for (const [key, value] of searchParams.entries()) {
            if (value.includes(',')) {
                filtersObject[key] = value.split(',').reduce((acc, val) => {
                    acc[val] = true;
                    return acc;
                }, {});
            } else {
                if (!filtersObject[key]) {
                    filtersObject[key] = {};
                }
                filtersObject[key][value] = true;
            }
        }
        setSelectedFilters(filtersObject);
    }, [searchParams]);

    const handleFilterChange = (category, id, checked, name = '') => {
        setSelectedFilters((prev) => {
            const updatedFilters = {
                ...prev,
                [category]: {
                    ...prev[category],
                    [id]: checked
                }
            };
            return updatedFilters;
        });
    };

    const handleSelectAll = (checked) => {
        setSelectedFilters((prev) => ({...prev,agency:checked ? Object.fromEntries(agencyList.map(i => [i.id, true])) :{} }))
    }

    const handleCategoryChange = (category, checked) => {
        const categoryFilters = filterCategories.find(c => c.name === category).options.reduce((acc, option) => {
            acc[option.id] = checked;
            return acc;
        }, {});

        setSelectedFilters(prev => ({
            ...prev,
            [category]: categoryFilters
        }));
    };

    const handleClear = () => {
        setSelectedFilters({});
        setSearchParams({});
        onClose();
    };


    const renderFilterCategory = ({ name, label, options }) => {
        
        const isAgencySource = name === 'agency';
   
        const renderCheckbox = (option, isChild = false) => {
         if(name === 'agency' && option.hide) return;
         return (
            <Box
            key={option.id}>
            <FormControlLabel
                key={option.id}
                label={capitalizeFirstLetter(option.name)}
                control={
                    <Checkbox
                        checked={selectedFilters[name]?.[option.id] || false}
                        onChange={(e) => handleFilterChange(name, option.id, e.target.checked)}
                    />
                }
                sx={{ pl: isChild ? 3 : 0 }} 
            />
            </Box>
        );
    }
    

        const renderGoogleOptions = (option) => (
            <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                        label={'Google'}
                        control={
                            <Checkbox
                                checked={agencyList.every(option => selectedFilters['agency']?.[option.id]) || false}
                                indeterminate={
                                    agencyList.some(option => selectedFilters['agency']?.[option.id]) &&
                                    !agencyList.every(option => selectedFilters['agency']?.[option.id])
                                }
                                onChange={(e) => { handleSelectAll(e.target.checked);}}
                            />
                        }
                        sx={{ cursor: 'pointer' }}
                    />
                </Box>
    
              
                {option.child && option.child.length > 0 && (
                    <Collapse in={true} sx={{ pl: 3 }}>
                        {option.child.map((agency) => renderCheckbox(agency, true))}
                    </Collapse>
                )}
            </>
        );
    
        return (
            <div key={name} className="col col-3 col-md-4 col-sm-12">
                <div className="top-popup-list">
                    <h5>
                        {capitalizeFirstLetter(label)}
                        <span className="top-popup-list-toggle">
                            <ExpandLessIcon />
                        </span>
                    </h5>
    
                    {/* "All" Checkbox for Non-Traffic Sources */}
                    {!isAgencySource && (
                        <FormControlLabel
                            label="All"
                            control={
                                <Checkbox
                                    checked={options.every(option => selectedFilters[name]?.[option.id])}
                                    indeterminate={
                                        options.some(option => selectedFilters[name]?.[option.id]) &&
                                        !options.every(option => selectedFilters[name]?.[option.id])
                                    }
                                    onChange={(e) => handleCategoryChange(name, e.target.checked)}
                                />
                            }
                        />
                    )}
    
                    {/* Options List */}
                    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                        {
                            options.map((option) =>
                            isAgencySource && option.name === 'Google'
                                ? renderGoogleOptions(option)
                                : renderCheckbox(option)
                        )}
                    </Box>
                </div>
            </div>
        );
    };
    

    const renderSelectedFilters = () => {
        return filterCategories.flatMap(category =>
            category.options
                .filter(option => selectedFilters[category.name]?.[option.id])
                .map(option => (
                    <label key={`${category.name}-${option.id}`}>
                        <span className="filter-category">{capitalizeFirstLetter(category.label)}:</span> {capitalizeFirstLetter(option.name)}
                        <span className="model-filter-labels-close">
                            <Close onClick={() => handleFilterChange(category.name, option.id, false)} />
                        </span>
                    </label>
                ))
        );
    };

    const handleApplyFilters = () => {
        const newSearchParams = new URLSearchParams();
        Object.entries(selectedFilters).forEach(([category, options]) => {
            const selectedOptions = Object.entries(options)
                .filter(([_, isSelected]) => isSelected)
                .map(([id, _]) => id);
            if (selectedOptions.length > 0) {
                newSearchParams.append(category, selectedOptions.join(','));
            }
        });
        setSearchParams(newSearchParams.toString());
        onClose();
    };

    if (isLoadingVerticals || isLoadingTrafficPlatformSources || isLoadingAgencies || isLoadingMediaBuyers) {
        return <CircularProgress />;
    }

    const containerStyle = isMobile ? {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    } : {};

    const topPopupStyle = isMobile ? {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    } : {};

    const topPopupInnerStyle = isMobile ? {
        overflowY: 'auto',
        flex: 1
    } : {};

    const modelFilterStyle = isMobile ? {
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        padding: '1rem',
        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
    } : {};

    const buttonContainerStyle = isMobile ? {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    } : {
        display: 'flex',
        justifyContent: 'flex-end'
    };

    const buttonStyle = isMobile ? {
        flex: 1,
        margin: '0 0.5rem'
    } : {
        marginLeft: '1rem'
    };

    return (
        <div className="top-popup-main" style={containerStyle}>
            <Box
                sx={{
                    bgcolor: theme.palette.background.topPopupmaintheme,
                    ...(isMobile && { position: 'sticky', top: 0, zIndex: 1 }),
                }}
                className="top-popup-main-title"
            >
                <FilterAlt /> Advanced Filters
            </Box>
            <div className="top-popup" style={topPopupStyle}>
                <div className="top-popup-inner" style={topPopupInnerStyle}>
                    <div className="row">
                        {filterCategories.map(renderFilterCategory)}
                    </div>
                </div>
                <Box
                    sx={{
                        backgroundColor: theme.palette.background.topPopupmaintheme,
                        ...modelFilterStyle,
                    }}
                    className="model-filter"
                >
                    <div className="row flex flex-wrap">
                        {!isMobile && (
                            <>
                                <div className="col col-2 col-md-12">
                                    <div className="model-filter-title">
                                        <h5>Selected Filters</h5>
                                    </div>
                                </div>
                                <div className="col col-6 col-md-12">
                                    <div className="model-filter-labels">
                                        {renderSelectedFilters()}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className={isMobile ? "col col-12" : "col col-3 col-md-12"}>
                            <div className="model-filter-buttons" style={buttonContainerStyle}>
                                <Button
                                    sx={{
                                        background: 'linear-gradient(62.72deg, #903AD9 6.2%, #4170E5 46.41%, #11BCC6 76.75%)',
                                        color: 'white',
                                        ...buttonStyle,

                                    }}
                                    onClick={handleApplyFilters}
                                    variant="contained"
                                    size="large"
                                >
                                    Apply
                                </Button>
                                <Button
                                    sx={{
                                        color: theme.palette.textcolors.headerplaningitemtext,
                                        ...buttonStyle,
                                    }}
                                    variant="outlined"
                                    size="large"
                                    color="error"
                                    onClick={handleClear}
                                >
                                    Clear
                                </Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>
        </div>
    );
}