import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import 'react-resizable/css/styles.css';
import App from "./App";
import { LicenseInfo } from '@mui/x-data-grid-pro';

const root = ReactDOM.createRoot(document.getElementById("root"));

LicenseInfo.setLicenseKey('b24c4b2013d2af835ac438cdddcdf38cTz05Mzg4MCxFPTE3NTIwNzYyNDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
