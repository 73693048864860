import React, { useState } from 'react';
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import { Box, useTheme, IconButton, Tooltip } from "@mui/material";
import { ChevronRight } from '@mui/icons-material';

const PrivateLayout = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const theme = useTheme();
    return (
        <div className="App">
            <div className="page-layout">
                <Box
                    sx={{ backgroundColor: theme.palette.background.headerColor, left:isCollapsed ? '-85px':'0px',transition: 'left 0.3s ease-in-out' }}
                    className="page-layout-left"
                >
                    <Sidebar />
                </Box>
                <Box className="page-layout-right" sx={{paddingLeft:isCollapsed ? '25px' :'95px',transition: 'padding 0.3s ease-in-out'}}>
                    <Header />
                    <Box
                        sx={{ backgroundColor: theme.palette.background.pageContent }}
                        className="page-content"
                    >
                        {children}
                    </Box>
                </Box>
                <Tooltip title={isCollapsed ? "Open Side Nav" : "Close Side Nav"} placement="right"
                componentsProps={{
                    tooltip: {
                        sx: {
                            fontSize: '1rem'}}}}>
                <IconButton
                onClick={() => setIsCollapsed(!isCollapsed)}
                sx={{
                    position: 'fixed',
                    zIndex:1000,
                    bottom: '100px',
                    left: isCollapsed ? '5px' :'75px',
                    transform: 'translateY(-50%)',
                    backgroundColor: '#1976d2',
                    color: '#fff',
                    borderRadius: '50%',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
                    transition: 'left 0.3s ease-in-out',
                    '&:hover': { backgroundColor: '#115293' },
                }}
            >
                 <ChevronRight />
            </IconButton>
            </Tooltip>
            </div> 
        </div>
    )
};

export default PrivateLayout;