const OperatorMapping = {
  '<': 'lt',
  '<=': 'lte',
  '>': 'gt',
  '>=': 'gte',
  '=': 'eq',
  '!=': 'ne',
  // String Operators
  'contains': 'iLike',
  'notContain': 'notILike',
  'startsWith': 'startsWith',
  'endsWith': 'endsWith',
  'isEmpty': 'isEmpty',
  'isNotEmpty': 'isNotEmpty',
  'equals': 'eq',
  'isAnyOf': 'in',
  };

const reverseOperatorMapping = {
  lt: "<",
  lte: "<=",
  gt: ">",
  gte: ">=",
  eq: "=",
  ne: "!=",
  // String Operators
  iLike: "contains",
  notILike: "notContain",
  startsWith: "startsWith",
  endsWith: "endsWith",
  isEmpty: "isEmpty",
  isNotEmpty: "isNotEmpty",
  eq: "equals",
  in: "isAnyOf",
};
  
export const getFormattedFilters = (filters) => {
    return Object.entries(filters).reduce((acc, [key, value]) => {
        if (value.length > 0) {
            acc[key] = value.join(',');
        }
        return acc;
    }, {});
};


export const createFilterRequestData = (data) => {
    // console.log("data ",data);
    const { items, logicOperator } = data;
    if(!Array.isArray(items)){
      return [];
    }
    const logic = items.length === 1 
    ? 'AND' 
    : logicOperator && typeof logicOperator === 'string' && logicOperator.trim() 
      ? logicOperator.trim().toUpperCase() 
      : 'AND';
    return items
      .filter((i) => (i.hasOwnProperty('value') && i.value) || i.operator === 'isEmpty' || i.operator === 'isNotEmpty')
      .map((i) => ({
        column: i.field,
        operator: OperatorMapping[i.operator], 
        value: i.value,
        logic
      }));
  };


  export const reverseFilterRequestData = (filters) => {
    if (!Array.isArray(filters) || filters.length === 0) {
        return { items: [], logicOperator: 'AND' };
    }

    const items = filters.map(({ column, operator, value }) => {
        const mappedOperator = reverseOperatorMapping[operator] || operator;

        // Construct item without 'value' key for 'isEmpty' and 'isNotEmpty'
        const item = {
            field: column,
            operator: mappedOperator
        };

        if (mappedOperator !== 'isEmpty' && mappedOperator !== 'isNotEmpty') {
            item.value = value;
        }

        return item;
    });

    const logicOperator = filters.every((filter) => filter.logic === filters[0].logic)
        ? filters[0].logic.toLowerCase() || 'and'
        : 'and';

    return { items, logicOperator };
};
